// ** Third Party Imports
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const BreadcrumbsWrapper = styled.nav`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 2rem;
`

export const BreadcrumbsItem = styled.div<{ $active?: boolean }>`
	display: flex;
	align-items: center;
`

export const BreadcrumbsLabel = styled.label<{ $active?: boolean }>`
	display: flex;
	align-items: center;
	cursor: pointer;
	${({ $active, theme }) =>
		$active && `color: ${theme.pallette.primary.main}; cursor: default;`}
`

export const BreadcrumbsLink = styled(Link)`
	text-decoration: none;
	${({ theme }) => `color: ${theme.pallette.text.black75};`}
`
