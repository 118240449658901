// ** React Imports
import { useState } from 'react'

// ** Third Party Imports
import { useNavigate } from 'react-router-dom'

// ** Component Imports
import { Button, Dialog, RadioButton } from '@/shared/ui'

// ** Styled Components
import {
	Title,
	Content,
	Description,
	ButtonsWrapper,
	PlusCircleIcon,
	StyledRadioGroup
} from './styles'

interface IProps {
	className?: string
}

export const CreateNewWidget = ({ className }: IProps) => {
	// ** State
	const [open, setOpen] = useState(false)
	const [value, setValue] = useState('')

	// ** Hooks
	const navigate = useNavigate()

	const handleOpenChange = (open: boolean) => {
		setOpen(open)
		setValue('')
	}

	const handleRedirect = () => {
		setOpen(false)
		navigate(value)
	}

	return (
		<Dialog open={open} onOpenChange={handleOpenChange}>
			<Dialog.Trigger>
				<Button
					label='Create New'
					className={className}
					startIcon={<PlusCircleIcon />}
				/>
			</Dialog.Trigger>
			<Content>
				<Dialog.Close />
				<Title variant='h2' weight='semi-bold'>
					Create New
				</Title>
				<Description variant='body2' weight='medium'>
					Please select the type of entity you want to create:
				</Description>
				<StyledRadioGroup value={value} onValueChange={setValue}>
					<RadioButton size='sm' value='/client-management/create-client' label='Client' />
					<RadioButton size='sm' value='/client-management/create-company' label='Company' />
					<RadioButton size='sm' value='/client-dashboard/create-plan' label='Plan' />
				</StyledRadioGroup>
				<ButtonsWrapper>
					<Dialog.Close>
						<Button fullWidth variant='secondary' label='Cancel' />
					</Dialog.Close>
					<Button
						label='Create'
						onClick={handleRedirect}
						disabled={value.length === 0}
						fullWidth
					/>
				</ButtonsWrapper>
			</Content>
		</Dialog>
	)
}
