// ** React Imports
import { Outlet } from 'react-router-dom'
import { Suspense } from 'react'

// ** Component Imports
import { FallbackLoader } from '@/shared/ui/elements/FallbackLoader'

export const DashboardLayout = () => {
	return (
		<>
			<Suspense fallback={<FallbackLoader fullHeight={false} />}>
				<Outlet />
			</Suspense>
		</>
	)
}
