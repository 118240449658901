// ** Third Party Imports
import styled from 'styled-components'

export const PromptAIContainer = styled.div<{
	$isAtBottom: boolean
	$bottomOffset?: string
}>`
	/* styling */
	cursor: pointer;
	padding: 0.5rem 1rem;
	box-shadow: 0px 2px 8px 0px rgba(246, 209, 125, 0.75);
	border-radius: 0.5rem;
	background-color: ${({ theme }) => theme.pallette.secondary.yellow};
	transition: transform 0.2s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}

	/* position */
	right: 2rem;
	bottom: 2rem;
	position: fixed;

	transition: bottom 0.2s ease-out;
	${({ $isAtBottom }) => $isAtBottom && `bottom: 5rem;`}
	${({ $bottomOffset }) => $bottomOffset && `bottom: ${$bottomOffset};`}
`

export const AIPromptedContainer = styled.div`
	/* styling */
	border: 1px solid ${({ theme }) => theme.pallette.secondary.yellowDisabled};
	display: flex;
	overflow: hidden;
	min-width: 250px;
	max-width: 350px;
	max-height: 85vh;
	box-shadow: 0px 2px 8px 0px rgba(246, 209, 125, 0.25);
	border-radius: 0.5rem;
	flex-direction: column;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};

	/* position */
	right: 0;
	margin: 0 2rem 1.5rem 2rem;
	bottom: 0;
	position: fixed;
	z-index: 100;
`
