// ** Third Party Imports
import { useLocation } from 'react-router-dom'

export const useBreadcrumbs = () => {
	const location = useLocation()
	const pathnames = location.pathname.split('/').filter((x) => x)

	const pathnamesWithoutNumbers = pathnames.filter((value, index) => {
		// ignore numeric values (IDs) or specific segments by index
		return isNaN(Number(value)) // exclude if the value is a number
	})

	return pathnames.length === 1
		? null
		: pathnamesWithoutNumbers.map((value, index) => {
				const path = `/${pathnamesWithoutNumbers.slice(0, index + 1).join('/')}`
				const label = value
					.split('-')
					.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' ')

				return { label, path }
		  })
}
