// ** React Imports
import { Outlet } from 'react-router-dom'
import { Suspense } from 'react'

// ** Component Imports
import { Breadcrumbs, FallbackLoader } from '@/shared/ui'

// ** Styled Components
import {
	LayoutWrapper,
	LayoutPageContent,
	LayoutContentWrapper
} from './styles'
import { Sidebar } from '@/widgets/Sidebar'

export const LayoutWithSidebar = () => {
	return (
		<LayoutWrapper>
			<Sidebar />
			<LayoutContentWrapper>
				<LayoutPageContent>
					<Breadcrumbs />
					<Suspense fallback={<FallbackLoader />}>
						<Outlet />
					</Suspense>
				</LayoutPageContent>
			</LayoutContentWrapper>
		</LayoutWrapper>
	)
}
